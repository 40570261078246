<template>
	<div>
		<template v-if="$vuetify.breakpoint.mdAndUp">
			<v-btn
				id="auto-sync-button"
				:text="$vuetify.breakpoint.smAndUp"
				:icon="$vuetify.breakpoint.xsOnly"
				@click="autoSyncing = !autoSyncing"
			>
				<font-awesome-icon icon="sync" :spin="autoSyncing"></font-awesome-icon>
				<span class="d-none d-sm-inline">
					&nbsp;
					{{ autoSyncing ? $t('auto_syncing') : $t('auto_sync') }}
				</span>
			</v-btn>
			<v-btn
				id="parlay-calculator-button"
				:text="$vuetify.breakpoint.smAndUp"
				:icon="$vuetify.breakpoint.xsOnly"
				@click="showCalculator = true"
			>
				<font-awesome-icon icon="calculator"></font-awesome-icon>
				<span class="d-none d-sm-inline">
					&nbsp; Parlay
				</span>
			</v-btn>
			<v-btn
				v-if="showLoginButton"
				:href="loginUrl"
				target="_blank"
				:text="$vuetify.breakpoint.smAndUp"
				:icon="$vuetify.breakpoint.xsOnly"
			>
				<font-awesome-icon icon="sign-in-alt" />
				<span class="d-none d-sm-inline"> &nbsp; {{ $t('login') }} </span>
			</v-btn>
		</template>
		<v-menu bottom transition="slide-y-transition" :close-on-content-click="false" offset-y>
			<template v-slot:activator="{ on }">
				<v-btn dark text v-on="on">
					{{ $t('results') }}
				</v-btn>
			</template>

			<v-card width="344" outlined>
				<v-card-title class="title">
					{{ $t('results') }}
				</v-card-title>

				<v-tabs grow centered>
					<v-tab key="sports">
						{{ $t('sports') }}
					</v-tab>
					<v-tab key="lotteries">
						{{ $t('lotteries') }}
					</v-tab>

					<v-tab-item>
						<v-card flat>
							<v-card-text>
								<v-list class="overflow-y-auto" max-height="300">
									<v-list-item href="https://www.mlb.com/scores" target="_blank">
										MLB
									</v-list-item>
									<v-list-item href="https://www.flashscore.com/baseball/mexico/lmb/" target="_blank">
										LMB
									</v-list-item>
									<v-list-item
										href="http://mlb.mlb.com/milb/stats/scoreboard.jsp?sid=l131&amp;t=l_sco&amp;lid=131"
										target="_blank"
									>
										LIDOM
									</v-list-item>
									<v-list-item href="https://www.nba.com/scores#/" target="_blank">
										NBA
									</v-list-item>
									<v-list-item href="https://www.wnba.com/scores/#/10/25/2019" target="_blank">
										WNBA
									</v-list-item>
									<v-list-item href="https://www.ncaa.com/scoreboard/basketball-men/d1" target="_blank">
										CBB
									</v-list-item>
									<v-list-item href="https://www.nhl.com/scores" target="_blank">
										NHL
									</v-list-item>
									<v-list-item href="https://www.nfl.com/scores" target="_blank">
										NFL
									</v-list-item>
									<v-list-item href="https://www.cbssports.com/college-football/scoreboard/" target="_blank">
										CFB
									</v-list-item>
									<v-list-item href="https://www.cfl.ca/" target="_blank">
										CFL
									</v-list-item>
									<v-list-item href="https://www.soccer24.com/" target="_blank">
										SOCCER
									</v-list-item>
									<v-list-item href="https://www.foxsports.com/ufc/results" target="_blank">
										UFC
									</v-list-item>
									<v-list-item href="https://www.gallerosoy.com/#/resultados" target="_blank">
										GALLOS
									</v-list-item>
								</v-list>
							</v-card-text>
						</v-card>
					</v-tab-item>
					<v-tab-item>
						<v-card flat>
							<v-card-text>
								<v-list-item href="https://loteriasdominicanas.com/" target="_blank">
									LOTERIA RD
								</v-list-item>
								<v-list-item href="https://www.lotterypost.com/results/ct" target="_blank">
									CONNECTICUT
								</v-list-item>
								<v-list-item href="https://www.lotterypost.com/results/fl" target="_blank">
									FLORIDA
								</v-list-item>
								<v-list-item href="https://www.lotterypost.com/results/nj" target="_blank">
									NEW JERSEY
								</v-list-item>
								<v-list-item href="https://www.lotterypost.com/results/md" target="_blank">
									MARYLAND
								</v-list-item>
								<v-list-item href="https://www.lotterypost.com/results/ny" target="_blank">
									NEW YORK
								</v-list-item>
								<v-list-item href="https://www.lotterypost.com/results/ga" target="_blank">
									GEORGIA
								</v-list-item>
							</v-card-text>
						</v-card>
					</v-tab-item>
				</v-tabs>
			</v-card>
		</v-menu>
		<v-menu v-if="$vuetify.breakpoint.smAndDown">
			<template v-slot:activator="{ on }">
				<v-btn dark text v-on="on">
					<font-awesome-icon icon="bars"></font-awesome-icon>
				</v-btn>
			</template>
			<v-card class="mx-auto" max-width="300" tile>
				<v-list dense>
					<v-subheader>Menu</v-subheader>
					<v-list-item-group color="primary">
						<v-list-item @click="showCalculator = true">
							<v-list-item-content>
								<v-list-item-title>
									<font-awesome-icon icon="calculator"></font-awesome-icon>
									&nbsp; {{ $t('parlay_calculator') }}
								</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
						<v-list-item @click="autoSyncing = !autoSyncing">
							<v-list-item-content>
								<v-list-item-title>
									<font-awesome-icon icon="sync" :spin="autoSyncing"></font-awesome-icon>
									&nbsp;
									{{ autoSyncing ? $t('auto_syncing') : $t('auto_sync') }}
								</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
						<v-list-item v-if="showLoginButton" @click="openLoginUrl">
							<v-list-item-content>
								<v-list-item-title>
									<font-awesome-icon icon="sign-in-alt" />
									&nbsp; {{ $t('login') }}
								</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
					</v-list-item-group>
				</v-list>
			</v-card>
		</v-menu>
		<v-menu bottom transition="slide-y-transition" :close-on-content-click="true" offset-y>
			<template v-slot:activator="{ on }">
				<v-btn dark text v-on="on">
					<font-awesome-icon icon="globe"></font-awesome-icon>
				</v-btn>
			</template>
			<v-list class="overflow-y-auto" max-height="300">
				<v-list-item @click="changeLanguage('en')">
					{{ $t('english') }}
				</v-list-item>
				<v-list-item @click="changeLanguage('es')">
					{{ $t('spanish') }}
				</v-list-item>
			</v-list>
		</v-menu>
		<parlay-calculator :show-dialog="showCalculator" @update:show-dialog="showCalculator = $event" />
	</div>
</template>

<script>
import { autoSync, notificationDispatch } from '@/eventbus/events';
import ParlayCalculator from './ParlayCalculator.vue';
import { CHANGE_LANGUAGE } from '../store/Mutations';

export default {
	components: { ParlayCalculator },
	data() {
		return {
			showCalculator: false,
			autoSyncing: false,
			loginUrl: process.env.LOGIN_URL
		};
	},
	computed: {
		showLoginButton() {
			return typeof process.env.LOGIN_URL !== 'undefined';
		}
	},
	watch: {
		autoSyncing(value) {
			this.$root.$emit(autoSync, value);
			if (value) {
				this.$root.$emit(notificationDispatch, {
					color: 'info',
					message: this.$t('auto_sync_enabled'),
					timeout: 2000
				});
			} else {
				this.$root.$emit(notificationDispatch, {
					color: 'info',
					message: this.$t('auto_sync_disabled'),
					timeout: 2000
				});
			}
		}
	},
	methods: {
		openLoginUrl() {
			window.open(process.env.LOGIN_URL, '_blank');
		},
		changeLanguage(lang) {
			this.$store.commit(CHANGE_LANGUAGE, lang);
		}
	}
};
</script>

<style scoped></style>
