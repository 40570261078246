var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-snackbar",
    { attrs: { value: _vm.showSnackbar, color: _vm.color } },
    [_vm._v("\n\t" + _vm._s(_vm.message) + "\n")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }