<template>
	<v-row justify="center" align="center">
		<v-col cols="12" md="11" lg="8">
			<v-img v-if="showLogo" :src="logoImg" max-width="336px" class="mx-auto my-3"></v-img>
			<v-slide-x-transition mode="out-in">
				<v-card v-if="showWelcomeMessage" :key="'welcome-message-card'" class="pt-0" loading>
					<v-card-title>
						{{ $t('welcome') }}
					</v-card-title>
					<v-card-text>
						{{ $t('loading_lines') }}
					</v-card-text>
				</v-card>
				<section v-else-if="leagueEntries.length > 0" key="leagues-section">
					<component
						v-for="leagueEntry in leagueEntries"
						:key="leagueEntry.league.identifier"
						:is="sectionFor(leagueEntry)"
						class="mb-4"
						:league="leagueEntry.league"
						:matchups="leagueEntry.matchUps"
					>
					</component>
				</section>
				<section v-else :key="'no-leagues-available-section'" class="d-flex align-center justify-center">
					<v-col cols="auto">
						<v-alert type="info">
							{{ $t('no_lines') }}
						</v-alert>
					</v-col>
				</section>
			</v-slide-x-transition>
		</v-col>
	</v-row>
</template>

<script>
import { fetchLines } from '@/api/lines.api';
import { autoSync, notificationDispatch } from '@/eventbus/events';
import Sports from '@/enumerations/Sport';
import Vue from 'vue';

export default Vue.extend({
	name: 'AppContent',
	components: {
		SectionBaseballLeague: () => import('./SectionBaseballLeague.vue'),
		SectionBaseballPlayersConfrontationLeague: () => import('./SectionBaseballPlayersConfrontationLeague.vue'),
		SectionFourPeriodBasketballLeague: () => import('./SectionFourPeriodBasketballLeague.vue'),
		SectionTwoPeriodBasketballLeague: () => import('./SectionTwoPeriodBasketballLeague.vue'),
		SectionBasketballPlayersConfrontationLeague: () => import('./SectionBasketballPlayersConfrontationLeague.vue'),
		SectionAmericanFootballLeague: () => import('./SectionAmericanFootballLeague.vue'),
		SectionSoccerLeague: () => import('./SectionSoccerLeague.vue'),
		SectionHockeyLeague: () => import('./SectionHockeyLeague.vue'),
		SectionPlayersConfrontationLeague: () => import('./SectionPlayersConfrontationLeague.vue'),
		SectionTeamAloneLeague: () => import('./SectionTeamAloneLeague.vue'),
		SectionLotteryLeague: () => import('./SectionLotteryLeague.vue'),
		SectionTennisLeague: () => import('./SectionTennisLeague.vue')
	},
	data() {
		return {
			showWelcomeMessage: true,
			leagueEntries: [],
			autoSyncInterval: null,
			logoImg: process.env.LOGO_URL
		};
	},
	destroyed() {
		this.$root.$off(autoSync);
	},
	mounted() {
		const AUTO_SYNC_INTERVAL = 20000;

		this.$root.$on(autoSync, autoSyncing => {
			if (autoSyncing) {
				this.autoSyncInterval = setInterval(() => {
					this.fetchData().then(() => {
						this.$root.$emit(notificationDispatch, {
							color: 'info',
							message: 'Lines updated successfully',
							timeout: 3500
						});
					});
				}, AUTO_SYNC_INTERVAL);
			} else {
				clearInterval(this.autoSyncInterval);
			}
		});
	},
	created() {
		this.fetchData().finally(() => {
			this.showWelcomeMessage = false;
		});
	},
	methods: {
		sectionFor(entry) {
			switch (entry.league.sport) {
				case Sports.Baseball.id:
					return 'SectionBaseballLeague';
				case Sports.BaseballPlayersConfrontation.id:
					return 'SectionBaseballPlayersConfrontationLeague';
				case Sports.FourPeriodBasketball.id:
					return 'SectionFourPeriodBasketballLeague';
				case Sports.TwoPeriodBasketball.id:
					return 'SectionTwoPeriodBasketballLeague';
				case Sports.BasketballPlayersConfrontation.id:
					return 'SectionBasketballPlayersConfrontationLeague';
				case Sports.AmericanFootball.id:
					return 'SectionAmericanFootballLeague';
				case Sports.Soccer.id:
					return 'SectionSoccerLeague';
				case Sports.Hockey.id:
					return 'SectionHockeyLeague';
				case Sports.PlayersConfrontation.id:
					return 'SectionPlayersConfrontationLeague';
				case Sports.TeamAlone.id:
					return 'SectionTeamAloneLeague';
				case Sports.Lottery.id:
					return 'SectionLotteryLeague';
				case Sports.Tennis.id:
					return 'SectionTennisLeague';
				default:
					return null;
			}
		},
		fetchData() {
			return fetchLines()
				.then(response => response.data)
				.then(data => {
					this.leagueEntries = data.filter(entry => entry.matchUps.length > 0);
				});
		}
	},
	computed: {
		showLogo() {
			return typeof process.env.LOGO_URL !== 'undefined';
		}
	}
});
</script>

<style>
@import '../scss/LineTable';
</style>
