import VueI18n from 'vue-i18n';
import messages from './messages';
import Vue from 'vue';
import store from '../store';
import Language from '../enumerations/Language.js';

Vue.use(VueI18n);

export default new VueI18n({
	locale: store?.state?.language || Language.Spanish,
	messages
});
