var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { attrs: { justify: "center", align: "center" } },
    [
      _c(
        "v-col",
        { attrs: { cols: "12", md: "11", lg: "8" } },
        [
          _vm.showLogo
            ? _c("v-img", {
                staticClass: "mx-auto my-3",
                attrs: { src: _vm.logoImg, "max-width": "336px" }
              })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "v-slide-x-transition",
            { attrs: { mode: "out-in" } },
            [
              _vm.showWelcomeMessage
                ? _c(
                    "v-card",
                    {
                      key: "welcome-message-card",
                      staticClass: "pt-0",
                      attrs: { loading: "" }
                    },
                    [
                      _c("v-card-title", [
                        _vm._v(
                          "\n\t\t\t\t\t" +
                            _vm._s(_vm.$t("welcome")) +
                            "\n\t\t\t\t"
                        )
                      ]),
                      _vm._v(" "),
                      _c("v-card-text", [
                        _vm._v(
                          "\n\t\t\t\t\t" +
                            _vm._s(_vm.$t("loading_lines")) +
                            "\n\t\t\t\t"
                        )
                      ])
                    ],
                    1
                  )
                : _vm.leagueEntries.length > 0
                ? _c(
                    "section",
                    { key: "leagues-section" },
                    _vm._l(_vm.leagueEntries, function(leagueEntry) {
                      return _c(_vm.sectionFor(leagueEntry), {
                        key: leagueEntry.league.identifier,
                        tag: "component",
                        staticClass: "mb-4",
                        attrs: {
                          league: leagueEntry.league,
                          matchups: leagueEntry.matchUps
                        }
                      })
                    }),
                    1
                  )
                : _c(
                    "section",
                    {
                      key: "no-leagues-available-section",
                      staticClass: "d-flex align-center justify-center"
                    },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "auto" } },
                        [
                          _c("v-alert", { attrs: { type: "info" } }, [
                            _vm._v(
                              "\n\t\t\t\t\t\t" +
                                _vm._s(_vm.$t("no_lines")) +
                                "\n\t\t\t\t\t"
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }