import axios from 'axios';
import { stringify } from 'qs';
import transform from 'lodash/transform';
import isObjectLike from 'lodash/isObjectLike';
import { camelCase } from 'change-case';

function transformKeys(data) {
	if (Array.isArray(data)) {
		return data.map(transformKeys);
	} else if (isObjectLike(data)) {
		return transform(
			data,
			(result, value, key) => {
				result[camelCase(key)] = transformKeys(value);
			},
			{}
		);
	}

	return data;
}

const url = new URL(process.env.API_URL),
	instance = axios.create({
		baseURL: `${url.protocol}//${url.host}/api`,
		timeout: 10000,
		paramsSerializer: stringify,
		transformRequest: data => {
			if (data instanceof FormData) {
				return data;
			}

			return stringify(data);
		},
		transformResponse: data => transformKeys(JSON.parse(data))
	});

export default instance;
