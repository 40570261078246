<template>
	<v-app id="inspire">
		<v-app-bar app :color="appColor" dark>
			<template v-slot:img="{ props }">
				<v-img v-bind="props" gradient="to top right, #377fecb3, rgba(25,32,72,.7)" />
			</template>
			<v-toolbar-title>Welcome!</v-toolbar-title>
			<v-spacer />
			<app-header />
			<v-btn icon @click="toggleDarkMode(!darkMode)">
				<font-awesome-icon v-if="darkMode" icon="sun"> </font-awesome-icon>
				<font-awesome-icon v-else icon="moon"> </font-awesome-icon>
			</v-btn>
		</v-app-bar>
		<v-content>
			<v-container>
				<app-content />
			</v-container>
		</v-content>
		<notifications />
	</v-app>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import { CHANGE_DARK_MODE_PREFERENCE } from '@/store/Mutations.js';
import Notifications from '@/components/Notifications.vue';
import AppHeader from '@/components/AppHeader.vue';
import AppContent from '@/components/AppContent.vue';
import Vue from 'vue';

export default Vue.extend({
	name: 'App',
	components: {
		Notifications,
		AppHeader,
		AppContent
	},
	data() {
		return {
			appColor: process.env.APP_COLOR
		};
	},
	computed: {
		...mapState(['darkMode'])
	},
	watch: {
		darkMode(value) {
			this.$vuetify.theme.dark = value;
		}
	},
	methods: {
		...mapMutations({
			toggleDarkMode: CHANGE_DARK_MODE_PREFERENCE
		})
	}
});
</script>
