var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.$vuetify.breakpoint.mdAndUp
        ? [
            _c(
              "v-btn",
              {
                attrs: {
                  id: "auto-sync-button",
                  text: _vm.$vuetify.breakpoint.smAndUp,
                  icon: _vm.$vuetify.breakpoint.xsOnly
                },
                on: {
                  click: function($event) {
                    _vm.autoSyncing = !_vm.autoSyncing
                  }
                }
              },
              [
                _c("font-awesome-icon", {
                  attrs: { icon: "sync", spin: _vm.autoSyncing }
                }),
                _vm._v(" "),
                _c("span", { staticClass: "d-none d-sm-inline" }, [
                  _vm._v(
                    "\n\t\t\t\t \n\t\t\t\t" +
                      _vm._s(
                        _vm.autoSyncing
                          ? _vm.$t("auto_syncing")
                          : _vm.$t("auto_sync")
                      ) +
                      "\n\t\t\t"
                  )
                ])
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "v-btn",
              {
                attrs: {
                  id: "parlay-calculator-button",
                  text: _vm.$vuetify.breakpoint.smAndUp,
                  icon: _vm.$vuetify.breakpoint.xsOnly
                },
                on: {
                  click: function($event) {
                    _vm.showCalculator = true
                  }
                }
              },
              [
                _c("font-awesome-icon", { attrs: { icon: "calculator" } }),
                _vm._v(" "),
                _c("span", { staticClass: "d-none d-sm-inline" }, [
                  _vm._v("\n\t\t\t\t  Parlay\n\t\t\t")
                ])
              ],
              1
            ),
            _vm._v(" "),
            _vm.showLoginButton
              ? _c(
                  "v-btn",
                  {
                    attrs: {
                      href: _vm.loginUrl,
                      target: "_blank",
                      text: _vm.$vuetify.breakpoint.smAndUp,
                      icon: _vm.$vuetify.breakpoint.xsOnly
                    }
                  },
                  [
                    _c("font-awesome-icon", { attrs: { icon: "sign-in-alt" } }),
                    _vm._v(" "),
                    _c("span", { staticClass: "d-none d-sm-inline" }, [
                      _vm._v("   " + _vm._s(_vm.$t("login")) + " ")
                    ])
                  ],
                  1
                )
              : _vm._e()
          ]
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-menu",
        {
          attrs: {
            bottom: "",
            transition: "slide-y-transition",
            "close-on-content-click": false,
            "offset-y": ""
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                return [
                  _c("v-btn", _vm._g({ attrs: { dark: "", text: "" } }, on), [
                    _vm._v(
                      "\n\t\t\t\t" + _vm._s(_vm.$t("results")) + "\n\t\t\t"
                    )
                  ])
                ]
              }
            }
          ])
        },
        [
          _vm._v(" "),
          _c(
            "v-card",
            { attrs: { width: "344", outlined: "" } },
            [
              _c("v-card-title", { staticClass: "title" }, [
                _vm._v("\n\t\t\t\t" + _vm._s(_vm.$t("results")) + "\n\t\t\t")
              ]),
              _vm._v(" "),
              _c(
                "v-tabs",
                { attrs: { grow: "", centered: "" } },
                [
                  _c("v-tab", { key: "sports" }, [
                    _vm._v(
                      "\n\t\t\t\t\t" + _vm._s(_vm.$t("sports")) + "\n\t\t\t\t"
                    )
                  ]),
                  _vm._v(" "),
                  _c("v-tab", { key: "lotteries" }, [
                    _vm._v(
                      "\n\t\t\t\t\t" +
                        _vm._s(_vm.$t("lotteries")) +
                        "\n\t\t\t\t"
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-tab-item",
                    [
                      _c(
                        "v-card",
                        { attrs: { flat: "" } },
                        [
                          _c(
                            "v-card-text",
                            [
                              _c(
                                "v-list",
                                {
                                  staticClass: "overflow-y-auto",
                                  attrs: { "max-height": "300" }
                                },
                                [
                                  _c(
                                    "v-list-item",
                                    {
                                      attrs: {
                                        href: "https://www.mlb.com/scores",
                                        target: "_blank"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t\t\tMLB\n\t\t\t\t\t\t\t\t"
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-list-item",
                                    {
                                      attrs: {
                                        href:
                                          "https://www.flashscore.com/baseball/mexico/lmb/",
                                        target: "_blank"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t\t\tLMB\n\t\t\t\t\t\t\t\t"
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-list-item",
                                    {
                                      attrs: {
                                        href:
                                          "http://mlb.mlb.com/milb/stats/scoreboard.jsp?sid=l131&t=l_sco&lid=131",
                                        target: "_blank"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t\t\tLIDOM\n\t\t\t\t\t\t\t\t"
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-list-item",
                                    {
                                      attrs: {
                                        href: "https://www.nba.com/scores#/",
                                        target: "_blank"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t\t\tNBA\n\t\t\t\t\t\t\t\t"
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-list-item",
                                    {
                                      attrs: {
                                        href:
                                          "https://www.wnba.com/scores/#/10/25/2019",
                                        target: "_blank"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t\t\tWNBA\n\t\t\t\t\t\t\t\t"
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-list-item",
                                    {
                                      attrs: {
                                        href:
                                          "https://www.ncaa.com/scoreboard/basketball-men/d1",
                                        target: "_blank"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t\t\tCBB\n\t\t\t\t\t\t\t\t"
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-list-item",
                                    {
                                      attrs: {
                                        href: "https://www.nhl.com/scores",
                                        target: "_blank"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t\t\tNHL\n\t\t\t\t\t\t\t\t"
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-list-item",
                                    {
                                      attrs: {
                                        href: "https://www.nfl.com/scores",
                                        target: "_blank"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t\t\tNFL\n\t\t\t\t\t\t\t\t"
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-list-item",
                                    {
                                      attrs: {
                                        href:
                                          "https://www.cbssports.com/college-football/scoreboard/",
                                        target: "_blank"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t\t\tCFB\n\t\t\t\t\t\t\t\t"
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-list-item",
                                    {
                                      attrs: {
                                        href: "https://www.cfl.ca/",
                                        target: "_blank"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t\t\tCFL\n\t\t\t\t\t\t\t\t"
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-list-item",
                                    {
                                      attrs: {
                                        href: "https://www.soccer24.com/",
                                        target: "_blank"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t\t\tSOCCER\n\t\t\t\t\t\t\t\t"
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-list-item",
                                    {
                                      attrs: {
                                        href:
                                          "https://www.foxsports.com/ufc/results",
                                        target: "_blank"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t\t\tUFC\n\t\t\t\t\t\t\t\t"
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-list-item",
                                    {
                                      attrs: {
                                        href:
                                          "https://www.gallerosoy.com/#/resultados",
                                        target: "_blank"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t\t\tGALLOS\n\t\t\t\t\t\t\t\t"
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-tab-item",
                    [
                      _c(
                        "v-card",
                        { attrs: { flat: "" } },
                        [
                          _c(
                            "v-card-text",
                            [
                              _c(
                                "v-list-item",
                                {
                                  attrs: {
                                    href: "https://loteriasdominicanas.com/",
                                    target: "_blank"
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t\tLOTERIA RD\n\t\t\t\t\t\t\t"
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "v-list-item",
                                {
                                  attrs: {
                                    href:
                                      "https://www.lotterypost.com/results/ct",
                                    target: "_blank"
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t\tCONNECTICUT\n\t\t\t\t\t\t\t"
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "v-list-item",
                                {
                                  attrs: {
                                    href:
                                      "https://www.lotterypost.com/results/fl",
                                    target: "_blank"
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t\tFLORIDA\n\t\t\t\t\t\t\t"
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "v-list-item",
                                {
                                  attrs: {
                                    href:
                                      "https://www.lotterypost.com/results/nj",
                                    target: "_blank"
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t\tNEW JERSEY\n\t\t\t\t\t\t\t"
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "v-list-item",
                                {
                                  attrs: {
                                    href:
                                      "https://www.lotterypost.com/results/md",
                                    target: "_blank"
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t\tMARYLAND\n\t\t\t\t\t\t\t"
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "v-list-item",
                                {
                                  attrs: {
                                    href:
                                      "https://www.lotterypost.com/results/ny",
                                    target: "_blank"
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t\tNEW YORK\n\t\t\t\t\t\t\t"
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "v-list-item",
                                {
                                  attrs: {
                                    href:
                                      "https://www.lotterypost.com/results/ga",
                                    target: "_blank"
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t\tGEORGIA\n\t\t\t\t\t\t\t"
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.$vuetify.breakpoint.smAndDown
        ? _c(
            "v-menu",
            {
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function(ref) {
                      var on = ref.on
                      return [
                        _c(
                          "v-btn",
                          _vm._g({ attrs: { dark: "", text: "" } }, on),
                          [
                            _c("font-awesome-icon", { attrs: { icon: "bars" } })
                          ],
                          1
                        )
                      ]
                    }
                  }
                ],
                null,
                false,
                2084251550
              )
            },
            [
              _vm._v(" "),
              _c(
                "v-card",
                {
                  staticClass: "mx-auto",
                  attrs: { "max-width": "300", tile: "" }
                },
                [
                  _c(
                    "v-list",
                    { attrs: { dense: "" } },
                    [
                      _c("v-subheader", [_vm._v("Menu")]),
                      _vm._v(" "),
                      _c(
                        "v-list-item-group",
                        { attrs: { color: "primary" } },
                        [
                          _c(
                            "v-list-item",
                            {
                              on: {
                                click: function($event) {
                                  _vm.showCalculator = true
                                }
                              }
                            },
                            [
                              _c(
                                "v-list-item-content",
                                [
                                  _c(
                                    "v-list-item-title",
                                    [
                                      _c("font-awesome-icon", {
                                        attrs: { icon: "calculator" }
                                      }),
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t\t  " +
                                          _vm._s(_vm.$t("parlay_calculator")) +
                                          "\n\t\t\t\t\t\t\t"
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-list-item",
                            {
                              on: {
                                click: function($event) {
                                  _vm.autoSyncing = !_vm.autoSyncing
                                }
                              }
                            },
                            [
                              _c(
                                "v-list-item-content",
                                [
                                  _c(
                                    "v-list-item-title",
                                    [
                                      _c("font-awesome-icon", {
                                        attrs: {
                                          icon: "sync",
                                          spin: _vm.autoSyncing
                                        }
                                      }),
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t\t \n\t\t\t\t\t\t\t\t" +
                                          _vm._s(
                                            _vm.autoSyncing
                                              ? _vm.$t("auto_syncing")
                                              : _vm.$t("auto_sync")
                                          ) +
                                          "\n\t\t\t\t\t\t\t"
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.showLoginButton
                            ? _c(
                                "v-list-item",
                                { on: { click: _vm.openLoginUrl } },
                                [
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _c(
                                        "v-list-item-title",
                                        [
                                          _c("font-awesome-icon", {
                                            attrs: { icon: "sign-in-alt" }
                                          }),
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t  " +
                                              _vm._s(_vm.$t("login")) +
                                              "\n\t\t\t\t\t\t\t"
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-menu",
        {
          attrs: {
            bottom: "",
            transition: "slide-y-transition",
            "close-on-content-click": true,
            "offset-y": ""
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                return [
                  _c(
                    "v-btn",
                    _vm._g({ attrs: { dark: "", text: "" } }, on),
                    [_c("font-awesome-icon", { attrs: { icon: "globe" } })],
                    1
                  )
                ]
              }
            }
          ])
        },
        [
          _vm._v(" "),
          _c(
            "v-list",
            { staticClass: "overflow-y-auto", attrs: { "max-height": "300" } },
            [
              _c(
                "v-list-item",
                {
                  on: {
                    click: function($event) {
                      return _vm.changeLanguage("en")
                    }
                  }
                },
                [_vm._v("\n\t\t\t\t" + _vm._s(_vm.$t("english")) + "\n\t\t\t")]
              ),
              _vm._v(" "),
              _c(
                "v-list-item",
                {
                  on: {
                    click: function($event) {
                      return _vm.changeLanguage("es")
                    }
                  }
                },
                [_vm._v("\n\t\t\t\t" + _vm._s(_vm.$t("spanish")) + "\n\t\t\t")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("parlay-calculator", {
        attrs: { "show-dialog": _vm.showCalculator },
        on: {
          "update:show-dialog": function($event) {
            _vm.showCalculator = $event
          }
        }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }