<template>
	<v-dialog :value="model" max-width="650px" @click:outside="closeDialog">
		<v-card outlined class="parlay-calculator">
			<v-card-title>
				{{ $t('parlay_calculator') }}
			</v-card-title>
			<v-row no-gutters>
				<v-col md="6" cols="12">
					<ul class="p-0">
						<li v-for="index in 7" :key="index">
							<v-text-field
								v-model.number="moneyLines[index - 1]"
								filled
								dense
								hide-details
								ref="money"
								@focus="$event.target.select()"
								@keyup.enter.prevent="handleEnter"
								@keydown="handleKeydown($event, index)"
							>
								<span class="addon" slot="prepend">
									{{ index }}
								</span>
							</v-text-field>
						</li>
					</ul>
				</v-col>
				<v-col md="6" cols="12">
					<li v-for="index in 3" :key="index">
						<v-text-field
							v-model.number="moneyLines[index + 6]"
							filled
							dense
							hide-details
							ref="money"
							@focus="$event.target.select()"
						>
							<span class="addon" slot="prepend">
								{{ index + 7 }}
							</span>
						</v-text-field>
					</li>
					<v-row no-gutters>
						<v-col>
							<div>
								<v-text-field v-model="bet" dense hide-details filled ref="betInput" @focus="$event.target.select()">
									<span class="space" slot="prepend">
										{{ $t('bet') }}
									</span>
								</v-text-field>
							</div>
							<div>
								<v-text-field :value="prize" disabled dense hide-details filled>
									<span class="space" slot="prepend">
										{{ $t('prize') }}
									</span>
								</v-text-field>
							</div>
							<div>
								<v-text-field :value="payment" disabled dense hide-details filled>
									<span class="space" slot="prepend">
										{{ $t('payment') }}
									</span>
								</v-text-field>
							</div>
						</v-col>
					</v-row>
				</v-col>
			</v-row>
			<v-card-actions class="justify-end">
				<v-btn color="success" @click="closeDialog">{{ $t('close') }}</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
/* eslint-disable */

  export default {
	name: 'ParlayCalculator',
	props: {
		showDialog: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			moneyLines: new Array(10).fill(0),
			bet: 0
		};
	},
	computed: {
		prize() {
			return Math.floor(this.multiplier * this.bet - this.bet);
		},
		payment() {
			return Math.floor(this.bet * this.multiplier);
		},
		multiplier() {
			let multiplier = 1;

			this.moneyLines
				.filter(m => m !== 0)
				.forEach(ml => {
					if (ml < 0) {
						multiplier *= 100 / Math.abs(ml) + 1;
					} else if (ml > 0) {
						multiplier *= ml / 100 + 1;
					}
				});

			return multiplier;
		},
		model: {
			get() {
				return this.showDialog;
			},
			set() {
				this.closeDialog();
			}
		}
	},
	methods: {
		handleKeydown(event, index) {
		  if (event.key === "-" && this.moneyLines[index - 1] !== 0) {
		    event.preventDefault();
		    event.stopPropagation();
		    this.$set(this.moneyLines, index - 1, this.moneyLines[index - 1] * -1);
		    return false;
		  }

		  return true;
		},
		handleEnter(event) {
			const target = event.target;

			if (target._value) {
				const index = this.$refs.money.map(x => x.$el.querySelector("input[type='text']")).indexOf(target);

				if (index === 9) {
					this.$refs.betInput.$el.querySelector("input[type='text']").focus();
				} else {
					this.$refs.money[index + 1].$el.querySelector("input[type='text']").focus();
				}
			} else {
				this.$refs.betInput.$el.querySelector("input[type='text']").focus();
			}
		},
		closeDialog() {
			this.$emit('update:show-dialog', false);
		}
	}
};
</script>

<style scoped>
li {
	list-style: none;
}
span.addon {
	background: gray;
	padding: 12px 15px;
	margin-bottom: 2px;
}
::v-deep .parlay-calculator .v-input__prepend-outer {
	margin: 0 !important;
}
.space {
	width: 100px;
	padding-left: 10px;
}
.p-0 {
	padding-left: 0 !important;
}
</style>
