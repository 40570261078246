import Vue from 'vue';

import store from './store';
import i18n from './i18n';

// Configuration & Theming
import vuetify from '@config/vuetify';
import './icons.js';

import App from './App.vue';

new Vue({
	el: '#app',
	vuetify,
	render: h => h(App),
	store,
	created() {
		i18n.locale = this.$store.state.language;
	},
	i18n
});
