var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    { attrs: { id: "inspire" } },
    [
      _c(
        "v-app-bar",
        {
          attrs: { app: "", color: _vm.appColor, dark: "" },
          scopedSlots: _vm._u([
            {
              key: "img",
              fn: function(ref) {
                var props = ref.props
                return [
                  _c(
                    "v-img",
                    _vm._b(
                      {
                        attrs: {
                          gradient: "to top right, #377fecb3, rgba(25,32,72,.7)"
                        }
                      },
                      "v-img",
                      props,
                      false
                    )
                  )
                ]
              }
            }
          ])
        },
        [
          _vm._v(" "),
          _c("v-toolbar-title", [_vm._v("Welcome!")]),
          _vm._v(" "),
          _c("v-spacer"),
          _vm._v(" "),
          _c("app-header"),
          _vm._v(" "),
          _c(
            "v-btn",
            {
              attrs: { icon: "" },
              on: {
                click: function($event) {
                  return _vm.toggleDarkMode(!_vm.darkMode)
                }
              }
            },
            [
              _vm.darkMode
                ? _c("font-awesome-icon", { attrs: { icon: "sun" } })
                : _c("font-awesome-icon", { attrs: { icon: "moon" } })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("v-content", [_c("v-container", [_c("app-content")], 1)], 1),
      _vm._v(" "),
      _c("notifications")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }