import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import Store from '../store';

Vue.use(Vuetify);

const opts = {
	theme: {
		dark: Store.state.darkMode
	}
};

export default new Vuetify(opts);
