import axios from '@/api';

const api = new URL(process.env.API_URL),
	groupId = api.searchParams.get('group_id');

export function fetchLines() {
	const params = groupId ? { group_id: groupId } : {};

	return axios.get('/lines', { params });
}
