export default {
	Baseball: {
		id: 1,
		name: 'Baseball'
	},
	BaseballPlayersConfrontation: {
		id: 2,
		name: 'BaseballPlayersConfrontation'
	},
	FourPeriodBasketball: {
		id: 3,
		name: 'FourPeriodBasketball'
	},
	TwoPeriodBasketball: {
		id: 4,
		name: 'TwoPeriodBasketball'
	},
	BasketballPlayersConfrontation: {
		id: 5,
		name: 'BasketballPlayersConfrontation'
	},
	AmericanFootball: {
		id: 6,
		name: 'AmericanFootball'
	},
	Soccer: {
		id: 7,
		name: 'Soccer'
	},
	Hockey: {
		id: 8,
		name: 'Hockey'
	},
	PlayersConfrontation: {
		id: 9,
		name: 'PlayersConfrontation'
	},
	TeamAlone: {
		id: 10,
		name: 'TeamAlone'
	},
	Lottery: {
		id: 11,
		name: 'Lottery'
	},
	Tennis: {
		id: 12,
		name: 'Tennis'
	}
};
