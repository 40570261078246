import Vue from 'vue';
import Vuex from 'vuex';
import { CHANGE_DARK_MODE_PREFERENCE, CHANGE_LANGUAGE } from './Mutations';
import createPersistedState from 'vuex-persistedstate';
import Language from '../enumerations/Language';
import I18n from '../i18n';

Vue.use(Vuex);

export default new Vuex.Store({
	plugins: [
		createPersistedState({
			paths: ['language', 'darkMode']
		})
	],
	state: {
		language: Language.English,
		darkMode: true
	},
	mutations: {
		[CHANGE_LANGUAGE](state, language) {
			state.language = language;
			I18n.locale = language;
		},
		[CHANGE_DARK_MODE_PREFERENCE](state, darkMode) {
			state.darkMode = darkMode;
		}
	}
});
