import { library } from '@fortawesome/fontawesome-svg-core';
import {
	faSun,
	faMoon,
	faPoll,
	faCalendarAlt,
	faSync,
	faChartLine,
	faBars,
	faInfo,
	faMapPin,
	faCalculator,
	faSignInAlt,
	faGlobe
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import Vue from 'vue';

library.add(faSun, faMoon, faPoll, faCalendarAlt, faSync, faChartLine, faBars, faInfo, faMapPin, faCalculator, faSignInAlt, faGlobe);

Vue.component('font-awesome-icon', FontAwesomeIcon);
