<template>
	<v-snackbar :value="showSnackbar" :color="color">
		{{ message }}
	</v-snackbar>
</template>

<script>
import { notificationDispatch } from '@/eventbus/events';

export default {
	name: 'Notifications',
	data() {
		return {
			timeout: null,
			showSnackbar: false,
			color: null,
			message: null
		};
	},
	destroyed() {
		this.$root.$off(notificationDispatch);
	},
	created() {
		this.$root.$on(notificationDispatch, ({ message, color, timeout: closeIn = 2000 }) => {
			this.showSnackbar = false;
			if (this.timeout) {
				clearTimeout(this.timeout);
			}

			this.$nextTick(() => {
				this.message = message;
				this.color = color;

				this.showSnackbar = true;
				this.timeout = setTimeout(() => {
					this.showSnackbar = false;

					if (this.timeout) {
						clearTimeout(this.timeout);
					}
				}, closeIn);
			});
		});
	}
};
</script>

<style scoped></style>
